import { type NextApiRequest, type NextApiResponse } from "next"

export enum AuthenticationErrorEnum {
  ERROR_UNWHITELIST = "ERROR_UNWHITELIST",
  ERROR_TOKEN_NOT_FOUND = "ERROR_TOKEN_NOT_FOUND",
  ERROR_ACCESS_TOKEN = "ERROR_ACCESS_TOKEN",
  ERROR_ACCESS_ROLE = "ERROR_ACCESS_ROLE",
  ERROR_GET_USER = "ERROR_GET_USER",
  ERROR_GET_INSTITUTION_BUYER = "ERROR_GET_INSTITUTION_BUYER",
  ERROR_GET_BUYER = "ERROR_GET_BUYER",
  ERROR_GET_SELLER = "ERROR_GET_SELLER",
  ERROR_GET_INTERNAL = "ERROR_GET_INTERNAL",
  ERROR_UNKNOWN = "ERROR_UNKNOWN",
  ERROR_TOKEN_INVALID = "ERROR_TOKEN_INVALID",
  ERROR_TOKEN_REFRESH = "ERROR_TOKEN_REFRESH",
  ERROR_SESSION_TOKEN_EXPIRED = "ERROR_SESSION_TOKEN_EXPIRED",
}

export enum TContractEnterpriseStatus {
  Rejected = "rejected",
  Verified = "verified",
  WaitingVerification = "waiting_verification",
}

type TProps = {
  session?: TSession
  apps?: TAppsName
  isInitialLogin?: boolean
}

export type TCallbackOptions = {
  forceRefresh?: boolean
  userRefresh?: boolean
  clearError?: boolean
  res?: NextApiResponse
  req?: NextApiRequest
}

export type TAccessTokenError = keyof typeof AuthenticationErrorEnum

export type TAccessToken = {
  accessToken?: string
  previousAccessToken?: string
  expiresIn?: number
  expiresAt?: number
  refreshToken?: string
  error?: TAccessTokenError
}

export type TDynamicObject = {
  [key: string]: unknown
}

export type TSession = {
  token?: TAccessToken
  user?: TDynamicObject
  error?: TAccessTokenError
  expires?: number
}

export type TUser = {
  session?: {
    token?: TAccessToken
    user?: TDynamicObject
    error?: TAccessTokenError
  }
  error?: string
}

export type TJWT = {
  token?: TAccessToken
  user?: TDynamicObject
  error?: TAccessTokenError
}

export type TRedirect = {
  url: string
  baseUrl: string
}

export type TAccount = {
  access_token?: string
  token_type?: string
  id_token?: string
  refresh_token?: string
  scope?: string
  expires_at?: number
  session_state?: string
  [key: string]: unknown
}

export type TProfile = {
  gtp_user_id?: string
  gtp_buyer_id?: string
  gtp_seller_id?: string
  ext_role?: string
  picture?: string
  nickname?: string
  name?: string
  email?: string
  email_verified?: string
}

export enum AppsNameEnum {
  BUYER = "BUYER",
  SELLER = "SELLER",
  INTERNAL = "INTERNAL",
  ACCOUNT = "ACCOUNT",
  BLACKLIST = "BLACKLIST",
  VERIFICATOR = "VERIFICATOR",
}

export enum AppsCookieName {
  ACCOUNT = "gtp.accessTokenAccount",
  BUYER = "gtp.accessTokenBuyer",
  SELLER = "gtp.accessTokenSeller",
  INTERNAL = "gtp.accessTokenInternal",
  BLACKLIST = "gtp.accessTokenBlacklist",
  VERIFICATOR = "gtp.accessTokenVerificator",
}

export const EXPIRED_TIME_TOKEN_COOKIE_NAME = "gtp.expiredToken"
export const LATEST_USER_LOGIN_COOKIE_NAME = "gtp.latestLogin"

export type TAppsName = keyof typeof AppsNameEnum

const loginProvider = {
  auth0: "auth0",
  refreshToken: "refreshToken",
}

export type TProvider = keyof typeof loginProvider

export type TDecodeJwt = {
  gtp_permissions: string[]
  gtp_user_id: string
  gtp_persona: {
    institutionId?: string
    legacyAppsUserId?: string
    legacyAppsUsername?: string
    personaId?: string
    personaOwner?: string
    role?: string
  }
}
